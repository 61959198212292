import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function IBMIcon() {

    return (
        <SvgIcon style={{ height: '42px', width: '70px' }}>
            <path d="M0 9.6v.876h6.23V9.6zm7.11 0v.876h8.876c0 0-.907-.876-2.107-.876zm10.618 0v.876h5.37l-.32-.876zm9.22 0l-.32.876h5.32V9.6zM0 11.303v.876h6.23v-.876zm7.11.001v.875h9.906c0 0-.116-.674-.317-.875zm10.618 0v.875h5.96l-.295-.875zm8.583 0l-.295.875h5.935v-.875zM1.8 13.006v.877h2.697v-.877zm7.11 0v.877H11.6v-.877zm5.32 0v.877h2.698c0 0 .17-.463.17-.877zm5.296 0v.877H24.3l-.32-.877zm6.205 0l-.32.877H30.2v-.877zM1.8 14.71v.876h2.697v-.876zm7.11 0v.876h6.9c0 0 .576-.45.76-.876zm10.618 0v.876h2.697V15.1l.17.487h4.94l.184-.487v.487H30.2v-.876h-5.064l-.27.742-.27-.742zM1.8 16.414v.876h2.697v-.876zm7.11 0v.876h7.65c-.184-.425-.76-.876-.76-.876zm10.618 0v.876h2.697v-.876zm3.188 0l.326.876h3.705l.3-.876zm4.806 0v.876H30.2v-.876zM1.8 18.117v.876h2.697v-.876zm7.11 0v.876H11.6v-.876zm5.32 0v.876h2.87c0-.413-.17-.876-.17-.876zm5.296 0v.876h2.697v-.876zm3.8 0l.316.876h2.484l.32-.876zm4.194 0v.876H30.2v-.876zM.05 19.82v.877h6.23v-.877zm7.063 0v.877h9.59c.202-.2.317-.877.317-.877zm10.666 0v.877h4.44v-.877zm6.155 0l.325.877h1.264l.305-.877zm3.58 0v.877H32v-.877zM.05 21.524v.876h6.23v-.876zm7.063 0v.875h6.77c1.2 0 2.108-.875 2.108-.875zm10.666 0v.876h4.44v-.876zm6.77 0l.313.873.054.001.318-.875zm2.964 0v.876H32v-.876z" />
        </SvgIcon>
    );
}
