import { Box, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DragAndDrop from "./DragAndDrop";

const UploadFile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


  return (
    <div>
        <DragAndDrop></DragAndDrop>
    </div>
  );

};

export default UploadFile;
