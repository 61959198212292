import { Box, Button } from "@mui/material";
import React from "react";

const Login = ({handleLogin}) => {
  return (
    <Box display={"flex"} justifyItems={"center"} justifyContent={"center"}>
      <Box
        display={"flex"}
        justifyItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        height={"100vh"}
      >
        <Box
          display={"flex"}
          justifyItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          width={"200px"}
          height={"200px"}
        >
          <Button size="large" variant="contained" onClick={handleLogin}>
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
